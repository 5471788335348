"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class OIDCHelper {
    constructor($window) {
        this.$window = $window;
        this.openidConfigPath = "/.well-known/openid-configuration";
    }
    buildServiceUrl() {
        let baseUrl = `${this.$window.location.protocol}//${this.$window.location.host}${this.$window.location.pathname}${this.$window.location.hash}`;
        return baseUrl.replace("#!/login", "").split("?")[0];
    }
    setState(phoneNumber = "") {
        return (phoneNumber.length > 0) ? `&state=${phoneNumber}` : '';
    }
    buildConfigUrl(serverUrl) { return serverUrl + this.openidConfigPath; }
    buildLoginUrl(OIDCServerConfiguration, phoneNumber, clientId) {
        return `${OIDCServerConfiguration.data.authorization_endpoint}?` +
            'client_id=' + encodeURIComponent(clientId) +
            '&redirect_uri=' + encodeURIComponent(this.buildServiceUrl()) +
            '&response_type=token' +
            '&response_mode=fragment' +
            '&scope=openid' +
            '&nonce=nonce' +
            this.setState(phoneNumber);
    }
    getPhoneNbFromState(url) {
        let params = Object.fromEntries(new URLSearchParams(url.split('?')[1]));
        return params === null || params === void 0 ? void 0 : params.state;
    }
}
exports.default = OIDCHelper;
OIDCHelper.$inject = ["$window"];
