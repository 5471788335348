import 'angular-translate-loader-partial';

export default function config($stateProvider, $urlRouterProvider, $translateProvider, $translatePartialLoaderProvider, localStorageServiceProvider) {
  
  const formatHash = (hash) => {
    if (!hash) return "";
    else if (hash.startsWith("#")) return hash.substring(1);
    else return hash;
  };

  const getHash = () => {
    let hash = window.location.href.split("#").pop();
    return formatHash(hash);
  };

  $stateProvider
    .state('login', {
      url: '/login?error',
      templateUrl: '/ucassistant/login.html',
      controller: 'LoginController',
      data: {
        requireLogin: false
      }
    })
    .state('interface', {
      url: '/ucassistant',
      templateUrl: '/ucassistant/main.html',
      controller: 'InitController as ctrl',
      data: {
        requireLogin: true
      }
    })
    .state('interface.menu', {
      url: '/menu',
      onEnter: ['$state', '$uibModal', function($state, $uibModal) {
        $uibModal.open({
          templateUrl: "/ucassistant/menu.html",
          controller: 'MenuController as ctrl',
          windowClass: 'modal-right',
          keyboard: false,
          backdrop: false
        }).result.then(angular.noop, angular.noop);
      }]
    })
    .state('interface.personalContact', {
      url: '/menu/personal-contact?id',
      params: {
        contact: {value: null, squash: true}
      },
      onEnter: ['$state', '$stateParams', '$uibModal', function($state, $stateParams, $uibModal) {
        $uibModal.open({
          templateUrl: "/ucassistant/personalContact.html",
          resolve: {
            contactId: () => {
              return $stateParams.id;
            },
            contactData: () => {
              return $stateParams.contact;
            }
          },
          controller: 'PersonalContactController as ctrl',
          windowClass: 'modal-right greyed',
          keyboard: false,
          backdrop: false
        }).result.then(angular.noop, angular.noop);
      }]
    })
    .state('interface.ringtoneDevice', {
      url: '/menu/ringtone-device',
      params: {
        contact: {value: null, squash: true}
      },
      onEnter: ['$state', '$stateParams', '$uibModal', function($state, $stateParams, $uibModal) {
        $uibModal.open({
          templateUrl: "/ucassistant/ringtoneDevice.html",
          resolve: {
            contactId: () => {
              return 0;
            },
            contactData: () => {
              return {};
            }
          },
          controller: 'RingtoneDeviceController as ctrl',
          windowClass: 'modal-right greyed',
          keyboard: false,
          backdrop: false
        }).result.then(angular.noop, angular.noop);
      }]
    })
    .state('interface.ringtoneSelection', {
      url: '/menu/ringtone-selection',
      params: {
        contact: {value: null, squash: true}
      },
      onEnter: ['$state', '$stateParams', '$uibModal', function($state, $stateParams, $uibModal) {
        $uibModal.open({
          templateUrl: "/ucassistant/ringtoneSelection.html",
          resolve: {
            contactId: () => {
              return 0;
            },
            contactData: () => {
              return {};
            }
          },
          controller: 'RingtoneSelectionController as ctrl',
          windowClass: 'modal-right greyed',
          keyboard: false,
          backdrop: false
        }).result.then(angular.noop, angular.noop);
      }]
    })
    .state('interface.meetingRooms', {
      url: '/menu/meeting-rooms?id',
      params: {
        room: {value: null, squash: true}
      },
      onEnter: ['$state', '$stateParams', '$uibModal', function($state, $stateParams, $uibModal) {
        $uibModal.open({
          templateUrl: "/ucassistant/meetingRooms.html",
          resolve: {
            roomId: () => {
              return $stateParams.id;
            }
          },
          controller: 'MeetingRoomsController as ctrl',
          windowClass: 'modal-right greyed',
          keyboard: false,
          backdrop: false
        }).result.then(angular.noop, angular.noop);
      }]
    })  
    .state('interface.favorites', {
      url: '/favorites',
      templateUrl: '/ucassistant/contacts.html',
      controller: "ContactsController",
      params: {
        showFavorites: true
      }
    })
    .state('interface.search', {
      url: '/search',
      templateUrl: '/ucassistant/contacts.html',
      controller: "ContactsController",
      params: {
        showFavorites: false,
        search: ''
      }
    })
    .state('interface.externalview', {
      templateUrl: 'assets/javascripts/xchelper/controllers/externalView.html',
      controller: 'ExternalViewController as ctrl'
    })
    .state('interface.history', {
      url: '/history',
      templateUrl: '/ucassistant/history.html',
      controller: "CallHistoryController as ctrl"
    })
    .state('interface.conversation', {
      url: '/conversation?remoteParty',
      controller: 'ConversationController as ctrl',
      templateUrl: "assets/javascripts/ucassistant/controllers/conversation.html"
    })
    .state('interface.conversationHistory', {
      url: '/conversation-history',
      controller: 'ConversationHistoryController as ctrl',
      templateUrl: "assets/javascripts/ucassistant/controllers/conversationHistory.html"
    });

  $urlRouterProvider.otherwise(function() {
    if (window.location.href.includes('#')) {
      return '/login?' + getHash();
    } else return '/login';
  });

  $translatePartialLoaderProvider.addPart('ucassistant');
  $translateProvider.useLoader('$translatePartialLoader', {
    urlTemplate: 'assets/i18n/{part}-{lang}.json'
  });
  $translateProvider.registerAvailableLanguageKeys(['en','fr','de'], {
    'en_*': 'en',
    'fr_*': 'fr',
    'de_*': 'de'
  });
  $translateProvider.preferredLanguage(document.body.getAttribute('data-preferredlang'));
  $translateProvider.fallbackLanguage(['fr']);
  $translateProvider.forceAsyncReload(true);
  $translateProvider.useSanitizeValueStrategy('escape');

  localStorageServiceProvider.setPrefix('pratix');
  localStorageServiceProvider.setNotify(true, true);
}
