export default function run($rootScope, $state, $window, XucLink, XucUser, XucChat, CtiProxy, $log, $transitions, ExternalEvent, electronWrapper, remoteConfiguration, MobileAppCompatibilityService, externalView) {
  $rootScope.$state = $state;


  remoteConfiguration.getOptional('iceGatheringTimeout', true).then(ms => {
    if (ms && ms.length > 0 && $window.SIPml) $window.SIPml.setCustomIceGatheringTimeout(ms);
  });

  $transitions.onStart({
    to: function(state) {
      return state.data !== null && state.data.requireLogin === true;
    }
  }, function(trans) {
    if (!XucLink.isLogged()) {
      $log.info("Login required");
      return trans.router.stateService.target('login');
    }
  });

  $transitions.onSuccess({ to: 'login' }, () => {
    electronWrapper.setElectronConfig({confirmQuit: {reset: true}});
    electronWrapper.setTrayIcon('logout');

    return remoteConfiguration.get('externalViewUrl').then(result => {
      externalView.setURL(result);
    });
  });
  $transitions.onSuccess({ from: 'login' }, () => {
    remoteConfiguration.get("version").then((serverVersion) => {
      if (serverVersion != $window.appVersion){
        $log.warn("Server version mismatch", serverVersion);

        $window.location.replace("/#!/login?error=Version");
        $window.location.reload();
      }
    });
    electronWrapper.setTrayIcon('default');
  });
}