

export default function remoteConfiguration() {

  this.handler = '/';
  this.isAgent = false;
  this.hasSwitchBoard = false;

  this.setHandler = (path) => {
    this.handler = path;
    this.isAgent = (path == '/ccagent/');
  };

  this.$get = ($http, XucLink) => {

    var _get = (name, onlyValue=true) => {
      return $http.get(this.handler + "config/" + name).then((resp) => {
        return onlyValue ? resp.data.value : resp.data;
      });
    };


    var _getOptional = (name, onlyValue=true) => {
      return $http.get(this.handler + "config/" + name).then((resp) => {
        return onlyValue ? resp.data.value : resp.data;
      }).catch(() => console.log(`Optional config key ${name} is not set`));
    };

    var _getFromXuc = (name) => {
      return $http.get(XucLink.getServerUrl('http')+"/xuc/config/" + name).then((resp) => {
        return resp.data.value;
      });
    };

    var _isAgent = () => {
      return this.isAgent;
    };

    var _hasSwitchBoard = () => {
      return this.hasSwitchboard;
    };

    var _setSwitchBoard = () => {
      this.hasSwitchboard = true;
    };

    var _setIsAgent = (bool) => {
      this.isAgent = bool;
    };

    var _getBoolean = (name) => {
      return _get(name).then((value) => {
        return value === "true";
      });
    };

    var _getBooleanOrElse = (name, defaultValue) => {
      return _get(name).then((value) => {
        return value === "true";
      }).catch(() => {return defaultValue;});
    };

    var _getInt = (name) => {
      return _get(name).then((value) => {
        return parseInt(value);
      });
    };

    var _getIntOrElse = (name, defaultValue) => {
      return _get(name).then((value) => {
        return parseInt(value,10);
      }).catch(() => {return defaultValue;});
    };

    return {
      get : _get,
      getOptional: _getOptional,
      getFromXuc: _getFromXuc,
      getBoolean: _getBoolean,
      getBooleanOrElse: _getBooleanOrElse,
      getInt: _getInt,
      getIntOrElse: _getIntOrElse,
      setIsAgent: _setIsAgent,
      isAgent: _isAgent,
      hasSwitchBoard: _hasSwitchBoard,
      setSwitchBoard: _setSwitchBoard,
      LOGIN_TIMEOUT_MS: 'loginTimeoutMs'
    };
  };
}
